<template>
	<div class="d-flex flex-column-fluid">
		<!--begin::Container-->
		<div class="container">
			<!--begin::Card-->
			<div class="card card-custom card-transparent">
				<div class="card-body p-0">
					<!--begin::Wizard-->
					<div class="wizard wizard-4" id="kt_wizard">
						<!--begin::Card-->
						<div class="card card-custom card-shadowless rounded-top-0">
							<!--begin::Body-->
							<div class="card-body p-0">
								<div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
									<div class="col-xl-12 col-xxl-10">
										<!--begin::Wizard Form-->
										<form class="form" id="kt_form">
											<div class="row justify-content-center parent-form-div">
												<div class="col-xl-9">
													<!--begin::Wizard Step 1-->
													<div class="my-5 step">
														<h5 class="text-dark font-weight-bold mb-10">
															New Account Profile Details:
														</h5>
														<!--begin::Group-->
														<div class="form-group row">
															<label class="col-xl-3 col-lg-3 col-form-label">Name</label>
															<div class="col-lg-9 col-xl-9">
																<input class="form-control form-control-lg" name="name" type="text" placeholder="Name" v-model="form.name" />
															</div>
														</div>
														<!--end::Group-->
														<!--begin::Group-->
														<!-- <div class="form-group row">
															<label class="col-xl-3 col-lg-3 col-form-label">Username</label>
															<div class="col-lg-9 col-xl-9">
																<input
																	class="form-control form-control-lg"
																	name="username"
																	type="text"
																	placeholder="Unique username"
																	v-model="form.username"
																/>
															</div>
														</div> -->
														<!--end::Group-->
														<!--begin::Group-->
														<div class="form-group row">
															<label class="col-xl-3 col-lg-3 col-form-label">Password</label>
															<div class="col-lg-9 col-xl-9">
																<div class="input-group input-group-lg">
																	<b-form-input class="form-control form-control-lg" name="password" v-model="form.password" disabled />
																	<div class="input-group-append">
																		<b-button variant="primary" @click="copyPw" v-b-tooltip.focus title="Password Copied to Clipboard">Copy</b-button>
																	</div>
																</div>
																<span class="form-text">
																	Autogenerated. Please write this down and save it.
																</span>
																<span class="form-text">
																	Users will be prompted to change this password when logging in for the first time.
																</span>
															</div>
														</div>
														<!--end::Group-->
														<!--begin::Group-->
														<div class="form-group row">
															<label class="col-xl-3 col-lg-3 col-form-label">Email Address</label>
															<div class="col-lg-9 col-xl-9">
																<div :class="{ hasError: !isEmailValid }" class="input-group input-group-lg">
																	<div class="input-group-prepend">
																		<span class="input-group-text">
																			<i class="fa fa-envelope" :class="{ hasError: !isEmailValid }"></i>
																		</span>
																	</div>
																	<input
																		type="email"
																		class="form-control form-control-lg"
																		:class="{ hasError: !isEmailValid }"
																		placeholder="Email Address"
																		name="email"
																		v-model="form.email"
																	/>
																</div>
															</div>
														</div>
														<!--end::Group-->
														<!--begin::Group-->
														<div class="form-group row">
															<label class="col-xl-3 col-lg-3 col-form-label">Role</label>
															<div class="col-lg-9 col-xl-9">
																<div class="input-group input-group-lg">
																	<select class="form-control" v-model="form.role">
																		<option v-for="(role, index) in roles" :key="role + index" :value="role">
																			{{ role }}
																		</option>
																	</select>
																</div>
															</div>
														</div>
														<!--end::Group-->
														<!-- begin::Checkboxes-->
														<div class="form-group row" v-if="form.role === 'user' || form.role === 'useradmin'">
															<label class="col-xl-3 col-lg-3 col-form-label"></label>
															<div class="col-lg-9 col-xl-9">
																<b-form-checkbox v-if="can_view_showapi" class="checkboxes" v-model="form.showapi">
																	Show API
																</b-form-checkbox>
																<b-form-checkbox v-if="can_view_realtime" class="checkboxes" v-model="form.showrealtime">
																	View Realtime Stats
																</b-form-checkbox>
															</div>
														</div>
														<!-- end::Checkboxes -->
													</div>
													<!--end::Wizard Step 1-->
													<div class="d-flex justify-content-between border-top pt-10 mt-15">
														<div class="mr-2">
															<button type="button" id="prev-step" class="btn btn-light font-weight-bolder px-9 py-4" @click.prevent="cancel()">
																Cancel
															</button>
														</div>
														<div>
															<button
																:disabled="!isEmailValid"
																type="button"
																class="btn btn-success font-weight-bolder px-9 py-4"
																@click.prevent="submit()"
															>
																Submit
															</button>
															<!-- <button type="button" id="next-step" class="btn btn-primary font-weight-bolder px-9 py-4" data-wizard-type="action-next">Next</button> -->
														</div>
													</div>
													<!--end::Wizard Actions-->
													<div v-if="success" class="alert alert-success alert-msg">
														<div class="mb-4"><strong>Success!</strong></div>
														<div class="input-group input-group-lg">
															<b-form-input class="form-control form-control-lg" name="password" v-model="form.password" disabled />
															<div class="input-group-append">
																<b-button variant="secondary" @click="copyPw" v-b-tooltip.focus title="Password Copied to Clipboard">Copy</b-button>
															</div>
														</div>
														<div class="mt-4 mb-4 underline">Click continue to proceed to the created account</div>
														<b-button variant="primary" @click="continueToAccount()" v-b-tooltip.focus title="continue">Continue</b-button>
													</div>
													<div v-if="error" class="alert alert-danger alert-msg"><strong>Error!</strong> {{ error }}</div>
												</div>
											</div>
										</form>
										<!--end::Wizard Form-->
									</div>
								</div>
							</div>
							<!--end::Body-->
						</div>
						<!--end::Card-->
					</div>
					<!--end::Wizard-->
				</div>
			</div>
			<!--end::Card-->
		</div>
		<!--end::Container-->
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import wordlist from '@/view/content/lib/dicelist.js';

export default {
	data() {
		return {
			loaded: false,
			avatar: '',
			form: {
				email: '',
				name: '',
				password: '',
				role: 'user',
				showapi: false,
				showlinecards: false,
				showrealtime: false,
				showtickets: true,
				username: ''
			},
			newID: '',
			passwordVerify: '',
			roles: [],
			can_view_realtime: false,
			can_view_linecards: false,
			can_view_showapi: false,
			success: false,
			error: '',
			reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
		};
	},
	watch: {
		'form.username': {
			deep: true,
			handler(val, oldVal) {
				if (this.error && val != oldVal) {
					this.error = '';
				}
			},
		},
	},
	computed: {
		...mapGetters(['currentUser']),
		isEmailValid() {
			return this.form.email == '' ? false : this.reg.test(this.form.email) ? true : false;
		},
	},
	methods: {
		loadPermissionsForUA() {
			if (this.currentUser.role == 'admin' || this.currentUser.role == 'noc') {
				this.can_view_linecards = true;
				this.can_view_realtime = true;
				this.can_view_showapi = true;
			} else if (this.currentUser.role == 'useradmin') {
				this.can_view_linecards = this.currentUser.showlinecards;
				this.can_view_realtime = this.currentUser.showrealtime;
				this.can_view_showapi = this.currentUser.showapi;
			}
		},
		generatePw() {
			let lowercase = 'abcdefghijklmnopqrstuvwxyz';
			let uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
			let numbers = '0123456789';
			let specialChars = '!@#$%^&*()_-+=<>?';

			// Function to get a random character from a string
			function getRandomChar(str) {
				return str[Math.floor(Math.random() * str.length)];
			}

			// Ensuring one character from each category
			let pw = [
				getRandomChar(lowercase),
				getRandomChar(uppercase),
				getRandomChar(numbers),
				getRandomChar(specialChars)
			];

			// Adding additional random characters to meet minimum length
			let allChars = lowercase + uppercase + numbers + specialChars;
			for (let i = pw.length; i < 10; i++) {
				pw.push(getRandomChar(allChars));
			}

			// Shuffle the array to randomize the order of characters
			for (let i = pw.length - 1; i > 0; i--) {
				let j = Math.floor(Math.random() * (i + 1));
				[pw[i], pw[j]] = [pw[j], pw[i]]; // Swap elements
			}

			// Setting the password
			this.form.password = pw.join('');
		},
		copyPw() {
			navigator.clipboard.writeText(this.form.password);
		},
		cancel() {
			this.$router.push('/admin');
		},
		submit() {
			this.form.username = this.form.email;
			this.$http
				.post('account', { data: this.form })
				.then(res => {
					console.log("res:::::", res)
					if (res.data.data[2]) {
						this.error = res.data.data[2];
					}
					if (res.data.data.AccountID != null) {
						this.error = '';
						this.success = true;
						this.newID = res.data.data.AccountID;
						// setTimeout(() => {
						// 	this.$router.push(`/admineditaccount/${res.data.data.AccountID}`);
						// 	this.success = false;
						// }, 3000);
					} else {
						console.log('Error with Account ID:', res.data.data[2]);
					}
				})
				.catch(err => {
					console.log('error:', err);
				});
		},
		continueToAccount() {
			this.$router.push(`/admineditaccount/${this.newID}`);
			this.success = false;
		}
	},
	created() {
		this.loadPermissionsForUA();
		if (this.currentUser.role === 'useradmin') {
			this.form.parent_id = this.currentUser.AccountID;
			this.roles = ['user', 'video', 'useradmin'];
		} else {
			this.roles = ['user', 'useradmin', 'video', 'staff', 'noc', 'admin'];
		}
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Admin New Account' }]);
		this.generatePw();
	},
};
</script>

<style scoped>

.parent-form-div {
	display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    position: relative; /* Needed for absolute positioning of child */
    height: 100%; /* Adjust as needed */
}

.alert-msg {
    position: absolute; /* Positions relative to the nearest positioned ancestor */
    top: 50%; /* 50% from the top of the parent */
    left: 50%; /* 50% from the left of the parent */
    transform: translate(-50%, -50%); /* Adjusts the position to truly center the element */
    /* Additional styles for visibility and layering */
    z-index: 10; /* Ensures it's on top of other elements */
    width: 80%; /* Adjust as needed */
}
.container {
	font-size: 1.1rem;
}
.input {
	border: 1px solid #000;
}
.hasError {
	color: red;
}
.alert-msg {
	text-align: center;
	padding: 20px;
	margin: 10px 0;
	border-radius: 15px;
	border-radius: 5px;
	font-weight: 700;
	font-size: 1.1rem;
}
</style>
